<template>
  <div class="smart-farm">
    <!-- 导航栏 -->
    <Nav :isHome="false"></Nav>
    <CommonNav pageName="水利产品"></CommonNav>
    <section>
      <img class="smart-farm-bg" src="../../assets/images/water/bg.png" alt="">
    </section>
    <section class="smart-farm-container">
      <p class="smart-farm-head">与水投集团、水利水电集团开展项目合作，针对特定情况自主研发智慧水务管理平台、智慧水文监测系统等。</p>
      <h4>智慧水务管理平台</h4>
      <p class="smart-farm-article">针对当前水利行业存在的管理粗放、体制不清、信息化水平低、缺乏系统与业务的融合、存在数据壁垒等痛点问题，四福科技抓住河长制推行的契机，通过云计算、大数据、服务融合等技术手段，以实现水利数据资源整合应用与共享为目的，以公共基础地理数据和水利核心业务数据为基础，打造数字化、智能化、可视化的智慧水利综合管理平台，为水利业务用户提供集浏览、查询、统计、分析于—体的综合管理平台，实现水利数据的全面感知、泛在服务、数据共享，科学决策。</p>
      <h5 class="smart-farm-title">核心技术及创新点：</h5>
      <p class="smart-farm-article">引入去中心化的边缘计算机制、在临近数据采集点的边缘计算节点完成计算与分析,使其原始数据不传回云端，而是仅向云端发送经过处理后的少数关键数据，大大减少云端服务器的数据存储量，避免云端服务器网络的堵塞。基于边缘计算的智能水位边缘端总控制平台解决了水务系统中因为区域广、采集设备多、数据处理量大而导致的云端服务器存储数据过多、通讯网络堵塞的问题。</p>
    </section>
    <section class="smart-farm-footer">
      <img src="../../assets/images/water/imgFirst.png" alt="">
    </section>
    <section class="smart-farm-container">
      <h4>智慧水文监测系统</h4>
      <p class="smart-farm-article">为解决当前水文监测系统中普遍采用的压力传感器式、超声波式、激光式等水位计在实际部署中受适用范围、测量精度、部署难度以及成本等因素限制等问题，我公司以互联网+为核心理念、依托云计算、视频压缩与传输技术、人工智能与计算机视觉技术，基于AIoT架构，研发了计算机视觉与人工智能水位识别系统。该系统可以实现动态水面监控、水位测量及数据管理，同时解放生产力，为水利、环保、市政、农业灌溉等行业提供水位数据+历史影像双重信息的无人值守解决方案。</p>
      <h5 class="smart-farm-title">核心技术及创新点：</h5>
      <p class="smart-farm-article">融合AI、大数据、可视化等互联网前沿技术，将水尺识别任务转化为数据建模问题，通过人工智能领域中的目标检测技术来求解，并采用先进的端到端（end-to-end）的训练手段来快速实现模型的收敛。</p>
      <p class="smart-farm-article">运用Web-RTC技术、H.265技术、分布式调度技术、实现视频高效传输、多级用户访问预览。</p>
    </section>
    <section class="smart-farm-footer">
      <img src="../../assets/images/water/imgSecond.png" alt="">
    </section>
     <!-- 底部 -->
     <Footer></Footer>
  </div>
</template>

<script>
import Nav from '@/components/nav.vue'
import CommonNav from '@/components/commonNav.vue'
import Footer from '@/components/footer.vue'

export default {
  data() {
    return {

    }
  },
  components: {
    Nav,
    CommonNav,
    Footer
  },
  beforeDestroy(){
    window.scrollTo(0,0)
  }
}
</script>

<style lang="scss" scoped>
.smart-farm{
  width: 100vw;
  padding-top: 120px;
}
.smart-farm-bg {
  width: 100%;
  height: 100%;
}
.smart-farm-container{
  width: 55%;
  max-width: 1057px;
  margin: 106px auto 104px;
  font-size: 22px;
  color: #808080;
  line-height: 2;
  text-align: left;
  .smart-farm-head{
    text-align: justify;
    /*兼容ie*/
    text-justify: distribute-all-lines;
  }
  h4{
    height: 45px;
    font-size: 30px;
    color: #202020;
    font-weight: 500;
    padding-left: 10px;
    border-left: 3px solid #C7020A;
    margin-top: 30px;
    line-height: 45px;
  }
  .smart-farm-article{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
    /*兼容ie*/
    text-justify: distribute-all-lines;
  }
  .smart-farm-title{
    color: #C7050F;
    margin-bottom: 20px;
    font-weight: 500;
  }
  h5{
    font-weight: 400;
    font-size: 22px;
  }
}
.smart-farm-footer{
  width: 55%;
  max-width: 1057px;
  margin: 0 auto 142px;
  text-align: center;
  img{
    width: 94%;
  }
}
</style>